// Libraries
import { useEffect } from 'react';

export const useClickOutsideNode = (node, onClickOutside) => {
  const handleClick = (event) => {
    if (node.current && !node.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);
};
