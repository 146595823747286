// TODO: This should be split up into seperate files for each component, the functionality
// here can also be refactored into reusable hooks

// Libraries
import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';

const DELIMITER = '::';
const PersistStateContext = createContext({ clearStorage: null });

export const hydrateState = (getKeyPrefix, propsToHydrate, props = {}) => {
  const keyPrefix = getKeyPrefix(props);
  return Object.entries(localStorage)
    .filter(([storageKey]) => {
      const [prefix, key] = storageKey.split(DELIMITER);

      return prefix === keyPrefix && propsToHydrate.includes(key);
    })
    .reduce((accumulator, [storageKey, storageValue]) => {
      const [, key] = storageKey.split(DELIMITER);
      accumulator[key] = JSON.parse(storageValue);

      return accumulator;
    }, {});
};

export const hydrateEngagementState = (
  getKeyPrefix,
  propsToHydrate,
  props,
  shouldClearStorage,
  setPersistedEngagement
) => {
  const keyPrefix = getKeyPrefix(props);
  const persistedState = hydrateState(getKeyPrefix, propsToHydrate, props);
  const preview = Boolean(props.engagement.meta?.preview);
  setPersistedEngagement(keyPrefix, persistedState, props.engagement, preview, shouldClearStorage);
};

export const persistState =
  (rootSelector, getKeyPrefix, propsToPersist, persistEnabled = () => true) =>
  (WrappedComponent) =>
    connect((state) => ({ rootState: rootSelector(state) }), null)(
      class extends Component {
        componentDidMount() {
          if (!persistEnabled(this.props)) return;
          Object.entries(this.props.rootState)
            .filter(([key]) => propsToPersist.includes(key))
            .forEach(([key, value]) => this.persist(key, value));
        }

        componentDidUpdate(prevProps) {
          if (!persistEnabled(this.props)) return;
          Object.entries(this.props.rootState)
            .filter(([key, value]) => propsToPersist.includes(key) && value !== prevProps[key])
            .forEach(([key, value]) => this.persist(key, value));
        }

        clearStorage = () => {
          const keyPrefix = getKeyPrefix(this.props);
          Object.keys(localStorage)
            .filter((key) => key.startsWith(keyPrefix))
            .forEach((key) => localStorage.removeItem(key));
        };

        persist(key, value) {
          const itemKey = getKeyPrefix(this.props) + DELIMITER + key;
          localStorage.setItem(itemKey, JSON.stringify(value));
        }

        render() {
          return (
            <PersistStateContext.Provider value={{ clearStorage: this.clearStorage }}>
              <WrappedComponent {...this.props} />
            </PersistStateContext.Provider>
          );
        }
      },
      propsToPersist
    );

export const withPersistContext = (WrappedComponent) => (props) =>
  (
    <PersistStateContext.Consumer>
      {(context) => <WrappedComponent {...props} persistContext={context} />}
    </PersistStateContext.Consumer>
  );
